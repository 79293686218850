import React from "react";
import { graphql, Link } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";

const AboutPage = ({ data }) => {
  const { page_header, text, image } = data.aboutPage.data;
  return (
    <Layout>
      <div className="flex flex-col flex-1 justify-center md:flex-row max-w-6xl mx-auto items-center h-full">
        <div className="flex-1">
          <img src={image.url} alt={image.alt} />
        </div>
        <div className="flex-1 px-6">
          <h1 className="text-5xl font-serif">{page_header}</h1>
          <div dangerouslySetInnerHTML={{ __html: text.html }} />
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  {
    aboutPage: prismicAboutPage {
      data {
        page_header
        text {
          html
        }
        image {
          url
          alt
        }
      }
    }
  }
`;

export default AboutPage;
